body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --body: #fff;

  --text: #000;
  --accent: #ffe15f;

  --two: #e69965;

  --secondBG-hover: #e2c74e;

  --danger: #d35b5d;
  --dangerHover: #bb4a4c;

  --widthMain: 1200px;
  --transition: 500ms;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body,
html {
	max-width: 100%;
  overflow-x: hidden;
	scroll-behavior: smooth;
}

body {
	color: var(--text);
	background: var(--body);
  font-family: "Poppins", sans-serif;
  position: relative;
}

p {
  margin-bottom: 0px;
}

:focus-visible {
  outline: inherit;
}

a {
	text-decoration: inherit;
	color: var(--text);
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}

a:hover {
  text-decoration: inherit;
  color: inherit;
}

a:focus {
  text-decoration: inherit;
  color: inherit;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin: 0px;
}

button {
  font-family: "Poppins", sans-serif;
}

input {
  font-family: "Poppins", sans-serif;
}

.button {
  font-family: "Poppins", sans-serif;
}

img {
  user-select: none;
}

/* MAIN DESIGN */

.JC-navBar {
  width: var(--widthMain);
  margin: 0 auto;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.JC-navLeft {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.JC-navLogo {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.JC-navImage {
  width: 130px;
}

.JC-navRight {
  display: flex;
  align-items: center;
  gap: 10px;
}

.JC-socialLink {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background: var(--accent);
  font-size: 20px;
  border: 2px solid var(--text);
  box-shadow: 0px 3px 0px #000;
  transition: var(--transition);
}

.JC-socialLink:hover {
  box-shadow: 0px 2px 0px #000;
  transition: var(--transition);
  background: var(--secondBG-hover);
}

.JC-TitleBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  text-align: center;
  margin-bottom: 30px;
}

.JC-Title {
  font-size: 70px;
  font-weight: 900;
  color: black;
  text-shadow: 0px 3px var(--accent);
  line-height: 70px;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: var(--accent);
}

.JC-Button {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  width: 200px;
  border-radius: 12px;
  border: 2px solid var(--text);
  margin-top: 15px;
  background: var(--danger);
  outline: inherit;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  transition: var(--transition);
}

.JC-Button:hover {
  background: var(--dangerHover);
  transition: var(--transition);
  cursor: pointer;
}

/* MAIN DESIGN */

.meme-generator {
  text-align: center;
  position: relative;
  padding-bottom: 50px;
}

.JC-MemeBody {
  position: relative;
}
  
.base-image {
  width: 600px;
  border: 6px solid var(--text);
  border-radius: 32px;
  background: var(--body);
}

.trait {
  position: relative;
  width: 800px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.JC-ArrowBox {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.trait-images {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trait1-edit {
  top: -300px;
}

.trait1-edit .JC-ArrowBox {
  top: 150px;
}

.trait2-edit {
  top: -310px;
}

.trait2-edit .JC-ArrowBox {
  top: -150px;
}

.trait3-edit {
  top: -310px;
}

.trait3-edit .JC-ArrowBox {
  top: 50px;
}

.trait5-edit {
  top: -310px;
}

.trait5-edit .JC-ArrowBox {
  top: -50px;
}

.trait-images img {
  width: 580px;
  position: absolute;
}

.trait1-edit img {
  width: 605px;
}

.JC-Arrow {
  height: 35px;
  width: 60px;
  border-radius: 8px;
  background: var(--accent);
  cursor: pointer;
  font-size: 16px;
  transition: var(--transition);
  border: 1px solid var(--text);
}

.JC-Arrow:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: var(--transition);
}

/* MEDIA QUERY */

@media only screen and (max-width: 1300px) {
  :root {
    --widthMain: 90%;
  }
}

@media only screen and (max-width: 900px) {
  .trait {
    width: 90%;
  }
}

@media only screen and (max-width: 850px) {
  .base-image {
    width: 450px;
  }

  .trait-images img {
    width: 450px;
  }

  .trait1-edit {
    top: -230px;
  }

  .trait2-edit {
    top: -230px;
  }

  .trait3-edit {
    top: -230px;
  }

  .trait5-edit {
    top: -230px;
  }

  .JC-Title {
    font-size: 50px;
  }

  .trait1-edit .JC-ArrowBox {
    top: 130px;
  }

  .trait2-edit .JC-ArrowBox {
    top: -130px;
  }

  .trait3-edit .JC-ArrowBox {
    top: 40px;
  }
}

@media only screen and (max-width: 650px) {
  .base-image {
    width: 300px;
  }

  .trait1-edit, .trait2-edit, .trait3-edit, .trait4-edit, .trait5-edit {
    top: -160px;
  }

  .trait-images img {
    width: 280px;
  }
}

@media only screen and (max-width: 500px) {
  .trait5-edit .JC-ArrowBox {
    top: -50px;
  }

  .trait2-edit .JC-ArrowBox {
    top: -100px;
  }

  .trait3-edit .JC-ArrowBox {
    top: 0px;
  }

  .trait1-edit .JC-ArrowBox {
    top: 50px;
  }

  .JC-TitleBox {
    margin-top: 50px;
  }

  .JC-Title {
    line-height: 50px;
  }
}